<template>
  <Head />
  <a
    href="#C"
    title="中央內容區塊，為本頁主要內容區"
    id="C"
    accesskey="C"
    name="C"
    class="visually-hidden-focusable"
    >:::</a
  >
  <PageBanner />
  <div
    class="container-fluid ps-0 pe-0 pt-3 pb-3 pt-lg-4 pb-lg-5 d-block position-relative"
    style="z-index: 10"
  >
    <div class="container">
      <div class="row g-2 g-lg-4">
        <div
          class="container pt-4 pt-lg-3 pb-lg-3 mt-3 rounded d-flex flex-column justify-content-center"
        >
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb border border-primary rounded p-3">
              <li class="breadcrumb-item"><a href="/">首頁</a></li>
              <li class="breadcrumb-item active" aria-current="page">教學工作坊</li>
            </ol>
          </nav>
          <div class="col-12">
            <div
              class="d-flex flex-row justify-content-between pb-0 section-title mb-2 mb-lg-4"
            >
              <h2 class="fw-bold d-flex align-items-center rounded-top pt-3 pb-3">
                <i
                  aria-hidden="true"
                  class="fa-solid fa-fw fa-cube me-3 font-1-5em"
                  style="color: #df592e"
                ></i
                ><span class="text-dark font-1-2em fw-bold">教學工作坊</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <h3 class="pt-2 pb-2 fw-bold">
        <i class="fa-solid fa-ellipsis-vertical me-3 text-primary"></i>活動說明
      </h3>
      <p class="lh-lg font-1-2em">
        本工作坊係配合國立臺灣圖書館委託東南科技大學執行「《蕃人觀光日誌》數位教材製作與應用計畫」之相關研習活動。本次工作坊議程安排，關注重點係圍繞《蕃人觀光日誌》內容而來。在十九世紀末的1897年8月，臺灣總督府規劃安排10位臺灣原住民部落頭目赴日參觀，而各地行程的所見所聞和言行紀錄，不僅反映了原住民族與帝國統治者兩造之間族群意識、生活習慣與文化差異，同時更是臺灣原住民族帝國初體驗的極佳顯影，值得今人玩味、思索。為了能更深刻掌握書中要義和當時概況，據此我們規劃三場專題演講，其一針對本次觀光行程具體細節加以闡釋，其二回溯殖民政府在「懷柔撫育」統治背景下，逐步建構的理蕃政策與原住民教育政策情形；其三透過臺灣原住民族的衣飾文化考察，裨益掌握數位教材中原住民各族人物形象與服飾設計，彰顯文化傳統與自我認同之意義。
      </p>
      <h3 class="pt-2 pb-2 fw-bold">
        <i class="fa-solid fa-ellipsis-vertical me-3 text-primary"></i>活動時間
      </h3>
      <p class="lh-lg font-1-2em">112年5月27日(星期六) 9：00AM~16：30PM</p>
      <h3 class="pt-2 pb-2 fw-bold">
        <i class="fa-solid fa-ellipsis-vertical me-3 text-primary"></i>活動地點
      </h3>
      <p class="lh-lg font-1-2em">
        國立臺灣圖書館視障資料中心B1研習教室(新北市中和區中安街85號)
      </p>
      <h3 class="pt-2 pb-2 fw-bold">
        <i class="fa-solid fa-ellipsis-vertical me-3 text-primary"></i>參與貴賓
      </h3>
      <p class="lh-lg font-1-2em">
        <strong>工作坊講師：</strong><br />
        鄭安睎 (臺中教育大學區域與社會發展學系副教授兼系主任)<br />
        王雅萍 (國立政治大學民族學系副教授)<br />
        李莎莉
        (財團法人福祿文化基金會執行長、原住民族委員會原住民族文化發展中心諮詢委員)<br />
        <strong>國立臺灣圖書館：</strong><br />
        推廣組主任吳瑛玲、研究助理張谷源<br />
        <strong>東南科技大學：</strong><br />
        研發長楊靖宇、副教授詹雅能、助理教授張美春、副教授林惠娟、副教授詹瑋、助理教授林文仁<br />
        <strong>工作坊學員：</strong><br />共36位對臺灣歷史有興趣之人士
      </p>
      <h3 class="pt-2 pb-2 fw-bold">
        <i class="fa-solid fa-camera-retro me-3 text-primary"></i>活動海報
      </h3>
      <div class="row mt-2 mb-5 g-2">
        <div class="col-12 col-lg-6">
          <img
            class="w-100"
            src="/files/workshop/工作坊-主視覺海報-RGB.jpg"
            alt="poker"
          />
        </div>
        <div class="col-12 col-lg-6">
          <img class="w-100" src="/files/workshop/工作坊-議程海報.jpg" alt="poker" />
        </div>
      </div>
      <h3 class="pt-2 pb-2 fw-bold">
        <i class="fa-solid fa-camera-retro me-3 text-primary"></i>活動花絮
      </h3>
      <div class="row mt-2 mb-5 g-2">
        <div class="col-lg-4" v-for="index in 20" :key="index">
          <div class="card border-0 rounded-0 loading">
            <div class="post_img border d-flex justify-content-center align-items-center">
              <a :href="getPhoto(index)" target="_blank">
                <img
                  :src="getPhoto(index)"
                  class="card-img-top rounded-0"
                  aria-hidden="true"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Foot />
</template>

<script>
import Head from "@/components/layout/Head.vue";
import Foot from "@/components/layout/Foot.vue";
import PageBanner from "@/components/PageBanner.vue";

export default {
  components: {
    Head,
    Foot,
    PageBanner,
  },
  methods: {
    getPhoto(index) {
      return "/files/workshop/photo_20230527/20230527 (" + index + ").jpg";
    },
  },
  watch: {
    $route() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
};
</script>

<style scoped>
.loading {
  background-image: url("/images/home/loading.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ffffff;
}
</style>
